/*
 * @Author: mxh
 * @Date: 2021-11-18 10:22:11
 * @LastEditTime: 2021-11-18 10:24:05
 * @LastEditors: mxh
 * @Description: 全局样式
 * @status: 
 */
.ant-tooltip-inner[data-v-b0e0d88b] {
  text-align: center;
}
/*********************************************/
textarea[data-v-b0e0d88b] {
  resize: none;
}
li[data-v-b0e0d88b] {
  list-style: none;
}
/*********************************************/
/*********************************************/
/*********************************************/
.flex_j_between[data-v-b0e0d88b] {
  display: flex;
  justify-content: space-between;
}
.flex_a_center[data-v-b0e0d88b] {
  display: flex;
  align-items: center;
}
.flex_between_center[data-v-b0e0d88b] {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.flex_center[data-v-b0e0d88b] {
  justify-content: center;
  display: flex;
  align-items: center;
}
/*********************************************/
#sideBar[data-v-b0e0d88b] {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
}
#sideBar[data-v-b0e0d88b] .ant-menu-item {
  position: relative;
}
#sideBar[data-v-b0e0d88b] .ant-menu-item .redPoint {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: red;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
}
#sideBar[data-v-b0e0d88b] .ant-menu-submenu.menuSeparator {
  border-top: 1px solid #444;
}
.logo[data-v-b0e0d88b] {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-menu-item[data-v-b0e0d88b]:first-child {
  margin-top: 0 !important;
}
