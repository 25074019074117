@font-face {
  font-family: "iconfont"; /* Project id 3189570 */
  src: url('iconfont.woff2?t=1645094603204') format('woff2'),
       url('iconfont.woff?t=1645094603204') format('woff'),
       url('iconfont.ttf?t=1645094603204') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yueliang:before {
  content: "\e662";
}

.icon-taiyang:before {
  content: "\e611";
}

