/*
 * @Author: mxh
 * @Date: 2021-11-18 10:22:11
 * @LastEditTime: 2021-11-18 10:24:05
 * @LastEditors: mxh
 * @Description: 全局样式
 * @status: 
 */
.ant-tooltip-inner {
  text-align: center;
}
/*********************************************/
textarea {
  resize: none;
}
li {
  list-style: none;
}
/*********************************************/
/*********************************************/
/*********************************************/
.flex_j_between {
  display: flex;
  justify-content: space-between;
}
.flex_a_center {
  display: flex;
  align-items: center;
}
.flex_between_center {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.flex_center {
  justify-content: center;
  display: flex;
  align-items: center;
}
/*********************************************/
/*
 * @Author: mxh
 * @Date: 2021-10-18 09:11:03
 * @LastEditTime: 2022-01-24 18:36:10
 * @LastEditors: mxh
 * @Description: 根样式
 */
#root {
  height: 100vh;
  -webkit-user-select: none;
          user-select: none;
}
