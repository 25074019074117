/*
 * @Author: mxh
 * @Date: 2021-11-18 10:22:11
 * @LastEditTime: 2021-11-18 10:24:05
 * @LastEditors: mxh
 * @Description: 全局样式
 * @status: 
 */
.ant-tooltip-inner[data-v-4e553b55] {
  text-align: center;
}
/*********************************************/
textarea[data-v-4e553b55] {
  resize: none;
}
li[data-v-4e553b55] {
  list-style: none;
}
/*********************************************/
/*********************************************/
/*********************************************/
.flex_j_between[data-v-4e553b55] {
  display: flex;
  justify-content: space-between;
}
.flex_a_center[data-v-4e553b55] {
  display: flex;
  align-items: center;
}
.flex_between_center[data-v-4e553b55] {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.flex_center[data-v-4e553b55] {
  justify-content: center;
  display: flex;
  align-items: center;
}
/*********************************************/
#header[data-v-4e553b55] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 20px;
}
#header .theme.ant-switch[data-v-4e553b55] {
  background-color: #324164;
}
#header .theme.ant-switch-checked[data-v-4e553b55] {
  background-color: #5ebedb;
}
#header .header_content[data-v-4e553b55] {
  font-size: 30px;
  line-height: 30px;
  color: pink;
  padding: 0 20px;
}
#header .heart[data-v-4e553b55] {
  font-size: 30px;
  color: pink;
}
#header .header_login[data-v-4e553b55] {
  height: 60px;
  width: 60px;
}
#header .header_login .ant-image img[data-v-4e553b55] {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
#header .header_login .ant-image .ant-image-mask[data-v-4e553b55] {
  width: 100%;
  border-radius: 50%;
}
#header .header_login[data-v-4e553b55] .userHead {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
