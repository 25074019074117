/*
 * @Author: mxh
 * @Date: 2021-11-18 10:22:11
 * @LastEditTime: 2021-11-18 10:24:05
 * @LastEditors: mxh
 * @Description: 全局样式
 * @status: 
 */
.ant-tooltip-inner {
  text-align: center;
}
/*********************************************/
textarea {
  resize: none;
}
li {
  list-style: none;
}
/*********************************************/
/*********************************************/
/*********************************************/
.flex_j_between {
  display: flex;
  justify-content: space-between;
}
.flex_a_center {
  display: flex;
  align-items: center;
}
.flex_between_center {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.flex_center {
  justify-content: center;
  display: flex;
  align-items: center;
}
/*********************************************/
#app {
  min-height: 100vh;
}
#app .ant-layout {
  min-height: 100vh;
}
#app .ant-layout .ant-layout-header {
  height: 100px;
  display: flex;
  align-items: center;
}
#app .site-layout {
  position: relative;
}
#app .site-layout .ant-layout-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  position: relative;
}
#app .site-layout .ant-layout-content > * {
  flex-shrink: 0;
}
#app .site-layout .ant-layout-content .ant-spin {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
}
#app .site-layout .ant-layout-content .ant-spin .ant-spin-text {
  padding-top: 20px;
  font-size: 15px;
}
#app .site-layout .ant-message {
  position: absolute;
}
#app .site-layout .site-layout-background.content {
  min-height: 360px;
  flex: 1 1;
}
